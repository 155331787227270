import broadcastTower from '~/images/broadcast-tower-solid.svg';
import creditCard from '~/images/credit-card-solid.svg';
import dumbbell from '~/images/dumbbell-solid.svg';
import gasPump from '~/images/gas-pump-solid.svg';
import medKit from '~/images/medkit-solid.svg';
import pizzaSlice from '~/images/pizza-slice-solid.svg';
import plane from '~/images/plane-departure-solid.svg';
import shoppingBasket from '~/images/shopping-basket-solid.svg';
import subway from '~/images/subway-solid.svg';
import ticket from '~/images/ticket-alt-solid.svg';
import tools from '~/images/tools-solid.svg';

import type { NumberInputItem } from '../types';

export const spendCategoryItems: NumberInputItem[] = [
  {
    label: 'Restaurants',
    name: 'restaurants',
    icon: pizzaSlice,
    defaultValue: 0,
  },
  {
    label: 'Gas stations',
    name: 'gasStations',
    icon: gasPump,
    defaultValue: 0,
  },
  {
    label: 'Grocery stores',
    name: 'groceryStores',
    icon: shoppingBasket,
    defaultValue: 0,
  },
  {
    label: 'Select travel',
    name: 'selectTravel',
    icon: plane,
    defaultValue: 0,
    tooltip:
      'Includes airline, hotel, cruise line and travel agency purchases.',
  },
  {
    label: 'Select transit',
    name: 'selectTransit',
    icon: subway,
    defaultValue: 0,
    tooltip:
      'Includes car rentals, ferries, commuter railways, subways, taxis/limousines/car services, passenger railways, bridge and road tolls, parking lots/garages, bus lines, and motor home and recreational vehicle rentals.',
  },
  {
    label: 'Select streaming services',
    name: 'selectStreamingServices',
    icon: broadcastTower,
    defaultValue: 0,
    tooltip:
      'Includes the following cable, satellite, and streaming providers: Amazon Prime Video, Amazon Music, Apple Music, Paramount+, Disney+, DirectTV Stream, ESPN+, fuboTV, HBO Max, NBA League Pass, Netflix, Pandora, Showtime, Sling TV, Spotify, Starz, SiriusXM, Vudu, YouTube Red, YouTube TV, and Tidal.',
  },
  { label: 'Drugstores', name: 'drugstores', icon: medKit, defaultValue: 0 },
  {
    label: 'Home improvement stores',
    name: 'homeImprovementStores',
    icon: tools,
    defaultValue: 0,
  },
  {
    label: 'Fitness clubs',
    name: 'fitnessClubs',
    icon: dumbbell,
    defaultValue: 0,
  },
  {
    label: 'Live entertainment',
    name: 'liveEntertainment',
    icon: ticket,
    defaultValue: 0,
  },
  {
    label: 'All other purchases',
    name: 'allOtherPurchases',
    icon: creditCard,
    defaultValue: 0,
  },
];

export const data = {
  spendCategoryItems,
  annualFee: 0,
};
