import { useMemo } from 'react';
import type { CalculatorComponent, OmitComponentAttrs } from '@cardo/types';
import { CalculatorInput } from '@cardo/ui';
import { formatInt } from '~/lib/utils';
import CalculatorStructure from '../components/CalculatorStructure';
import { useCalculator } from '~/hooks/useCalculator';
import type { NumberInputItem } from '../types';
import IntroOfferSection from '../components/IntroOfferSection';
import CalculatorInputGroup from '~/components/input/CalculatorInputGroup';
import CalculatorSection from '../components/CalculatorSection';
import ExpectedValue from '../components/ExpectedValue';
import ValueWithBar from '../components/ValueWithBar';

export default function CitiCustomCashCalculator({
  heading,
  calculator,
  showCalculationMethodology,
}: OmitComponentAttrs<
  CalculatorComponent & { showCalculationMethodology?: boolean }
>) {
  const { state, dispatch, hasIntroOffer, earnedIntroOffer, data, bonusValue } =
    useCalculator();
  const { spendCategories } = state;
  const { spendCategoryItems } =
    (data as {
      spendCategoryItems: NumberInputItem[];
      annualFee: number;
    }) ?? {};

  const totalMonthlySpend = useMemo(
    () =>
      Object.values(spendCategories).reduce<number>(
        (acc, value) => acc + (Number(value) ?? 0),
        0
      ),
    [spendCategories]
  );

  const sortedSpendCategories = useMemo(
    () =>
      Object.entries(spendCategories).sort((a, b) => {
        const aSpend = Number(a[1]);
        const bSpend = Number(b[1]);
        return bSpend - aSpend;
      }),
    [spendCategories]
  );

  const topSpendCategory = sortedSpendCategories[0];

  const cashBackOnFirstFiveHundred = useMemo(() => {
    const topSpendCategoryAmount = Number(topSpendCategory[1]);
    return (
      (topSpendCategoryAmount <= 500 ? topSpendCategoryAmount : 500) * 0.05
    );
  }, [topSpendCategory]);
  const cashBackOnRemaining = useMemo(() => {
    const topSpendCategoryAmount = Number(topSpendCategory[1]);
    return topSpendCategoryAmount > 500
      ? (topSpendCategoryAmount - 500) * 0.01
      : 0;
  }, [topSpendCategory]);

  const totalCashBackMonthly = useMemo(() => {
    const totalCashBack = sortedSpendCategories.reduce(
      (acc, category, index) => {
        const spend = Number(category[1]);

        if (index === 0) {
          return acc + cashBackOnFirstFiveHundred + cashBackOnRemaining;
        }

        return acc + spend * 0.01;
      },
      0
    );

    return totalCashBack;
  }, [sortedSpendCategories, cashBackOnFirstFiveHundred, cashBackOnRemaining]);

  const totalCashBackYearly = useMemo(() => {
    return totalCashBackMonthly * 12;
  }, [totalCashBackMonthly]);

  return (
    <CalculatorStructure
      heading={heading}
      cardName="Citi Custom Cash℠ Card"
      showCalculationMethodology={showCalculationMethodology}
    >
      {hasIntroOffer &&
        calculator.data?.attributes.introOfferSection && ( // extra check just to make ts happy
          <IntroOfferSection
            heading="Citi Custom Cash Welcome Offer"
            colorClassName="border-b-theme-blue-dark"
            introOffer={calculator.data?.attributes.introOfferSection}
            showBonusOfferInput={
              calculator.data?.attributes.introOfferSection.canChangeBonusAmount
            }
          />
        )}

      <CalculatorSection
        heading="Monthly category spend"
        splitHeading={true}
        colorClassName="border-b-theme-blue-dark"
      >
        <div className="flex flex-grow flex-col sm:flex-row divide-y sm:divide-y-0 sm:divide-x">
          <div className="flex basis-1/2 flex-col p-6 gap-4">
            {spendCategoryItems.map((spendCategoryItem, idx) => (
              <CalculatorInputGroup
                key={`${spendCategoryItem.label}_${idx}`}
                className="2xl:col-span-2"
                {...spendCategoryItem}
                stateObj={spendCategories}
                stateKey="spendCategories"
                dispatch={dispatch}
                labelInputContainerClassName="sm:flex-row"
              />
            ))}
            <div className="bg-theme-blue-dark h-1 w-full" />
            <div className="flex gap-3 items-center">
              <div className="w-6" />
              <label className="flex flex-col sm:flex-row gap-2 sm:justify-between flex-grow items-start sm:items-center">
                <span>Total monthly spend</span>
                <CalculatorInput
                  currency={true}
                  type="text"
                  value={totalMonthlySpend}
                  readOnly
                  disabled
                />
              </label>
            </div>
          </div>
          <div className="flex basis-1/2 flex-col gap-4">
            <div className="flex flex-grow flex-col px-8 py-10 gap-12 justify-center">
              <p>Yearly cash back breakdown:</p>
              <div className="flex flex-col gap-6">
                <ValueWithBar
                  value={cashBackOnFirstFiveHundred * 12}
                  comparisonValue={totalCashBackYearly}
                  label="5% Cash back"
                  colorClassName="bg-theme-blue-dark"
                  prefix="$"
                />
                <ValueWithBar
                  value={
                    (totalCashBackMonthly - cashBackOnFirstFiveHundred) * 12
                  }
                  comparisonValue={totalCashBackYearly}
                  label="1% Cash back"
                  colorClassName="bg-theme-purple-light"
                  prefix="$"
                />
              </div>
              <div className="flex justify-between">
                <span>Total cash back per year:</span>
                <span className="text-theme-blue-dark">
                  ${formatInt(Math.floor(totalCashBackYearly))}
                </span>
              </div>
            </div>
            <ExpectedValue
              earnedIntroOffer={earnedIntroOffer}
              totalValuePerYear={totalCashBackYearly}
              bonusValue={bonusValue}
              calculationExplanationText="(Welcome offer +  spend)"
              containerClassName="text-white"
              bgColorClassName="bg-theme-blue-dark"
              headingColorClassName="text-white"
              heading="Expected Cash Back Value"
            />
          </div>
        </div>
      </CalculatorSection>
    </CalculatorStructure>
  );
}
