import type { IntroOfferQuestionComponent } from '@cardo/types';
import type { CalculatorAction } from '~/hooks/useCalculator';

type EligibilityQuestionProps = {
  dispatch: React.Dispatch<CalculatorAction>;
} & IntroOfferQuestionComponent;

export default function EligibilityQuestion({
  text,
  questionKey,
  trueLabel,
  falseLabel,
  dispatch,
}: EligibilityQuestionProps) {
  return (
    <div className="flex flex-col gap-4">
      <p>{text}</p>
      <div className="flex flex-col sm:flex-row gap-4 sm:gap-10">
        <label className="flex items-center gap-3 w-32 cursor-pointer">
          <input
            type="radio"
            name={questionKey}
            value="yes"
            onChange={(e) => {
              dispatch({
                type: 'update-eligibility-question',
                name: questionKey,
                value: e.target.value,
              });
            }}
          />
          <span>{trueLabel || 'Yes'}</span>
        </label>
        <label className="flex items-center gap-3 w-32 cursor-pointer">
          <input
            type="radio"
            name={questionKey}
            value="no"
            onChange={(e) => {
              dispatch({
                type: 'update-eligibility-question',
                name: questionKey,
                value: e.target.value,
              });
            }}
          />
          <span>{falseLabel || 'No'}</span>
        </label>
      </div>
    </div>
  );
}
