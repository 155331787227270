import creditCard from '~/images/credit-card-solid.svg';
import plane from '~/images/plane-departure-solid.svg';
import wifi from '~/images/wifi-solid.svg';
import hamburger from '~/images/hamburger-solid.svg';
import shoppingBasket from '~/images/shopping-basket-solid.svg';
import car from '~/images/car-solid.svg';
import hotel from '~/images/hotel-solid.svg';

import type { NumberInputItem, Redemption } from '../types';

export const spendCategoryItems: NumberInputItem[] = [
  {
    label: 'Travel via Chase Travel℠',
    name: 'travelViaChaseUltimateRewards',
    icon: plane,
    defaultValue: 3_000,
    validation: {
      min: 0,
    },
    tooltip:
      'Excluding hotel purchases that qualify for the $50 Annual Ultimate Rewards Hotel Credit.',
  },
  {
    label: 'Lyft purchases',
    name: 'lyftPurchases',
    icon: car,
    defaultValue: 500,
    validation: {
      min: 0,
    },
    tooltip:
      "Earn 5x points on Lyft rides through March 31, 2025. That's 3x points in addition to the 2x points you already earn on travel.",
  },
  {
    label:
      'Dining, including eligible delivery services, takeout and dining out.',
    name: 'dining',
    icon: hamburger,
    defaultValue: 10_000,
    validation: {
      min: 0,
    },
  },
  {
    label: 'Online grocery purchases',
    name: 'onlineGroceryPurchases',
    icon: shoppingBasket,
    defaultValue: 1_000,
    validation: {
      min: 0,
    },
    tooltip: 'Excludes Target, Walmart and wholesale clubs.',
  },
  {
    label: 'Select streaming services',
    name: 'selectStreamingServices',
    icon: wifi,
    defaultValue: 1_000,
    validation: {
      min: 0,
    },
  },
  {
    label: 'Other travel purchases',
    name: 'otherTravelPurchases',
    icon: plane,
    defaultValue: 5_000,
    validation: {
      min: 0,
    },
  },
  {
    label: 'All other purchases',
    name: 'allOtherPurchases',
    icon: creditCard,
    defaultValue: 1_000,
    validation: {
      min: 0,
    },
  },
];

export const perksItems: NumberInputItem[] = [
  {
    label:
      'Up to $50 statement credit for hotel stays via Chase Travel℠ each card anniversary year',
    name: 'hotelStaysViaChaseUltimateRewards',
    icon: hotel,
    defaultValue: 50,
    validation: {
      min: 0,
      max: 50,
    },
    tooltip:
      'A statement credit will automatically be applied to your account when used for qualifying hotel purchases via the Ultimate Rewards program, up to an annual accumulation of $50. The $50 will not earn reward points.',
  },
  {
    label: 'Up to $15 in Instacart+ credit each quarter',
    name: 'instacartCredit',
    icon: hamburger,
    defaultValue: 0,
    validation: {
      min: 0,
      max: 60,
    },
    tooltip:
      'Get 6 months of complimentary Instacart+ when you activate by July 31, 2024. Instacart+ members earn up to $15 in statement credits each quarter through July 2024. Membership auto-renews.',
  },
  {
    label: 'DoorDash DashPass Subscription',
    name: 'doordashDashPassSubscription',
    icon: car,
    defaultValue: 0,
    validation: {
      min: 0,
      max: 96,
    },
    tooltip:
      'Activate by December 31, 2027. Get complimentary access to DashPass - a membership for both DoorDash and Caviar - which unlocks $0 delivery fees and lower service fees on eligible orders for a minimum of one year',
  },
  {
    label: 'Primary collision damage waiver (CDW)',
    name: 'primaryCollisionDamageWaiver',
    icon: shoppingBasket,
    defaultValue: 100,
    validation: {
      min: 0,
    },
    tooltip: 'Terms apply.',
  },
  {
    label: 'Other travel protections (trip delay, baggage delay, etc)',
    name: 'otherTravelProtections',
    icon: plane,
    defaultValue: 50,
    validation: {
      min: 0,
    },
  },
];

export const redemptionItems: Redemption[] = [
  {
    label: 'Statement credit = 1 CPP',
    name: 'statementCredit',
    value: 1,
  },
  {
    label: 'Gift cards = 1 CPP',
    name: 'giftCards',
    value: 1,
  },
  {
    label: 'Travel via Chase Travel Portal = 1.25 CPP',
    name: 'travelViaChaseTravelPortal',
    value: 1.25,
  },
  {
    label: 'Transferring to airline partners (target value) = 2 CPP',
    name: 'transferringToAirlinePartners',
    value: 2,
  },
  {
    label: 'Custom',
    name: 'custom',
    value: null,
    acceptInput: true,
  },
];

export const data = {
  spendCategoryItems,
  perksItems,
  redemptionItems,
  annualFee: 95,
};
