import creditCard from '~/images/credit-card-solid.svg';
import hotel from '~/images/hotel-solid.svg';
import plane from '~/images/plane-departure-solid.svg';
import passport from '~/images/passport-solid.svg';
import adobe from '~/images/adobe.svg';
import wifi from '~/images/wifi-solid.svg';
import laptop from '~/images/laptop-solid.svg';
import moneyBill from '~/images/money-bill-solid.svg';
import briefcase from '~/images/briefcase-solid.svg';

import type { NumberInputItem, Redemption } from '../types';

export const spendCategoryItems: NumberInputItem[] = [
  {
    label: 'Flights and prepaid hotels on amextravel.com (5X points)',
    name: 'flightsAndHotels',
    icon: plane,
    defaultValue: 5_000,
    validation: {
      min: 0,
    },
  },
  {
    label: 'Purchases of $5,000 or more ',
    name: 'purchasesOf5000',
    icon: moneyBill,
    defaultValue: 20_000,
    validation: {
      min: 0,
    },
    tooltip:
      'Earn 1.5X on eligible purchases of $5,000 or more, on up to $2 million of these purchases per calendar year, then 1X after that. Purchases must be a single transaction of $5,000+ to qualify. Other terms may apply.',
  },
  {
    label:
      'Eligible purchases in key business categories at U.S. construction material & hardware suppliers, electronic goods retailers and software & cloud system providers, and shipping providers.',
    name: 'keyBusinessCategories',
    icon: creditCard,
    defaultValue: 2_000,
    validation: {
      min: 0,
    },
  },
  {
    label: 'All other purchases',
    name: 'allOtherPurchases',
    icon: creditCard,
    defaultValue: 2_000,
    validation: {
      min: 0,
    },
  },
];

export const creditsItems: NumberInputItem[] = [
  {
    label: '$400 Dell Technologies Credit',
    name: 'dellCredit',
    icon: laptop,
    defaultValue: 400,
    validation: {
      min: 0,
      max: 400,
    },
    tooltip:
      'Get up to $200 in statement credits semi-annually, totaling $400 per calendar year, toward U.S. purchases with Dell Technologies. Enrollment required.',
  },
  {
    label: '$150 Adobe Creative Solutions',
    name: 'adobeCredit',
    icon: adobe,
    defaultValue: 0,
    validation: {
      min: 0,
      max: 150,
    },
    tooltip:
      'Get up to $150 back per year for select business subscription purchases with Adobe. Enrollment Required.',
  },
  {
    label: '$360 Indeed Credit',
    name: 'indeedCredit',
    icon: briefcase,
    defaultValue: 360,
    validation: {
      min: 0,
      max: 360,
    },
    tooltip:
      'Get up to $360 in statement credits per year for purchases with Indeed. Enrollment required.',
  },
  {
    label: '$120 Wireless Credit',
    name: 'wirelessCredit',
    icon: wifi,
    defaultValue: 120,
    validation: {
      min: 0,
      max: 120,
    },
    tooltip:
      'Get up to $120 per calendar year for purchases with U.S. wireless telephone service providers.',
  },
  {
    label: '$200 Airline Fee Credit',
    name: 'airlineFeeCredit',
    icon: plane,
    defaultValue: 200,
    tooltip:
      'Receive up to $200 per calendar year in statement credit when you charge airline incidental fees from one qualifying airline to your card. Enrollment required.',
    validation: {
      min: 0,
      max: 200,
    },
  },
  {
    label: '$189 CLEAR® Credit',
    name: 'clearCredit',
    icon: passport,
    defaultValue: 189,
    tooltip:
      'Up to $189 in statement credit per year when you pay with your Business Platinum Card. Enrollment required.',
    validation: {
      min: 0,
      max: 189,
    },
  },
];

export const perksItems: NumberInputItem[] = [
  {
    label: 'Centurion Lounges',
    name: 'centurionLoungePerk',
    icon: plane,
    defaultValue: 100,
    validation: {
      min: 0,
    },
  },
  {
    label: 'Delta Sky Clubs',
    name: 'deltaSkyClubPerk',
    icon: plane,
    defaultValue: 50,
    validation: {
      min: 0,
    },
    tooltip: 'Only accessible with a same-day Delta flight.',
  },
  {
    label: 'Priority Pass and other partner airport  lounges',
    name: 'priorityPassPerk',
    icon: plane,
    defaultValue: 50,
    validation: {
      min: 0,
    },
    tooltip: 'Enrollment required. Terms apply.',
  },
  {
    label: 'Hotel status (Marriott Gold + Hilton Gold)',
    name: 'hotelStatusPerk',
    icon: hotel,
    defaultValue: 0,
    validation: {
      min: 0,
    },

    tooltip: 'Enrollment required.',
  },
  {
    label: 'Access to Fine Hotels and Resorts Program',
    name: 'fineHotelsPerk',
    icon: hotel,
    defaultValue: 0,
    validation: {
      min: 0,
    },
    tooltip:
      'Get benefits like $100 dining or spa credit, 1-level room upgrades, or late checkout when you book through the Fine Hotels and Resorts program via Amex Travel. Minimum 2-night stay required. Benefits vary by property. Terms apply.',
  },
  {
    label: 'Other benefits (Car rental status, Resy, travel protections, etc.)',
    name: 'otherBenefits',
    icon: creditCard,
    defaultValue: 0,
    validation: {
      min: 0,
    },
  },
];

export const redemptionItems: Redemption[] = [
  {
    label: 'Statement credit = 0.6 CPP',
    name: 'statementCredit',
    value: 0.6,
  },
  {
    label: 'American Express® Business Checking = 0.8 CPP',
    name: 'businessChecking',
    value: 0.8,
  },

  {
    label: 'Booking travel = 1 CPP',
    name: 'bookingTravel',
    value: 1,
  },
  {
    label: 'Amex Business Checking w/ Business Platinum = 1 CPP',
    name: 'businessCheckingWithBusinessPlatinum',
    value: 1,
  },
  {
    label: 'Cashing out via Schwab Platinum = 1.1 CPP',
    name: 'cashingOutViaSchwabPlatinum',
    value: 1.1,
  },
  {
    label: 'Booking travel w/ Business Platinum rebate = 1.25 CPP',
    name: 'bookingTravelWithBusinessPlatinumRebate',
    value: 1.25,
  },
  {
    label: 'Transferring to airline partners (target value) = 2 CPP',
    name: 'transferringToAirlinePartners',
    value: 2,
  },
  {
    label: 'Custom',
    name: 'custom',
    value: null,
    acceptInput: true,
  },
];

export const data = {
  spendCategoryItems,
  creditsItems,
  perksItems,
  redemptionItems,
  annualFee: 695,
};
