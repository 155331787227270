import { Link } from '@remix-run/react';
import classnames from 'classnames';
import { IoArrowForwardSharp } from 'react-icons/io5';
import type { FeaturedCardsComponent, OmitComponentAttrs } from '@cardo/types';

export default function FeaturedCards({
  heading,
  subheading,
  cards,
  seeMoreUrl,
}: OmitComponentAttrs<FeaturedCardsComponent>) {
  return (
    <div className="flex flex-grow flex-col items-center">
      {heading && (
        <h2
          className={classnames('text-center', {
            'mb-3 sm:mb-14': !subheading,
            'mb-3': !!subheading,
          })}
        >
          {heading}
        </h2>
      )}
      {subheading && <h5 className="mb-14">{subheading}</h5>}
      <div className="flex flex-col flex-wrap divide-y divide-indigo-700 sm:flex-row sm:divide-y-0 xl:flex-nowrap xl:divide-x">
        {cards
          .filter((card) => card.card?.data)
          .map((card) => (
            <div
              className="w-full pb-2 pt-4 sm:w-1/2 sm:flex-1 sm:pr-4 sm:first:pl-0 sm:even:border-l sm:even:border-indigo-700 sm:even:pl-8 xl:w-auto xl:pl-8 xl:even:border-l-0"
              key={card.id}
            >
              <FeaturedCard
                cardArtUrl={card.card.data?.attributes.cardArtUrl}
                cardName={card.card.data?.attributes.cardName}
                marketingLine={card.card.data?.attributes.introOfferShort}
                link={`/go/${encodeURIComponent(
                  card.card.data?.attributes.slug ??
                    card.card.data?.attributes.cardName
                )}`}
              />
            </div>
          ))}
        <Link
          to={seeMoreUrl || '/cards'}
          className="group flex flex-shrink flex-row items-center pt-8 text-lg font-bold no-underline sm:mt-4 sm:border-l sm:border-indigo-700 sm:pl-8 xl:mt-0 xl:border-l-0"
        >
          <span className="text-theme-purple mr-2 whitespace-nowrap">
            See more
          </span>
          <IoArrowForwardSharp className="text-theme-purple -rotate-45 text-2xl transition-transform group-hover:rotate-0" />
        </Link>
      </div>
    </div>
  );
}

interface FeaturedCardProps {
  cardArtUrl: string | null;
  cardName: string;
  marketingLine: string | null;
  link: string;
}

function FeaturedCard({
  cardArtUrl,
  cardName,
  marketingLine,
  link,
}: FeaturedCardProps) {
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className="text-theme-blue-darkest group flex space-x-3 no-underline"
    >
      <div className="flex flex-col space-y-3 bg-transparent">
        {cardArtUrl && (
          <figure className="flex w-36 flex-grow items-start">
            <img
              src={cardArtUrl}
              alt={`card art for the ${cardName}`}
              className="mx-auto w-full rounded-lg object-contain"
            />
          </figure>
        )}
        <div className="flex flex-row items-center text-lg font-bold no-underline">
          <span className="text-theme-purple mr-2 whitespace-nowrap">
            View offer
          </span>
          <IoArrowForwardSharp className="text-theme-purple -rotate-45 text-2xl transition-transform group-hover:rotate-0" />
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <h5>{cardName}</h5>
        {marketingLine && <p className="font-bold">{marketingLine}</p>}
      </div>
    </a>
  );
}
