import classNames from 'classnames';
import type {
  CallToActionSectionComponent,
  OmitComponentAttrs,
} from '@cardo/types';
import DOMPurify from 'isomorphic-dompurify';
import { Link } from '@remix-run/react';
import { IoArrowForwardSharp } from 'react-icons/io5';

export default function CallToAction({
  heading,
  subheading,
  content,
  ctaButtonText,
  ctaButtonLink,
  ctaButtonColor,
}: OmitComponentAttrs<CallToActionSectionComponent>) {
  return (
    <div className="flex flex-col gap-6 pb-10">
      <div className="flex flex-col gap-4">
        {heading && <h2 className="text-center">{heading}</h2>}
        {subheading && (
          <h3 className="text-center text-base font-normal">{subheading}</h3>
        )}
      </div>
      {content && (
        <div
          className="prose-sm max-w-none"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(content),
          }}
        ></div>
      )}
      {ctaButtonText && ctaButtonLink && (
        <div className="flex items-center justify-center">
          <Link
            to={ctaButtonLink}
            className={classNames(
              'group flex flex-row items-center rounded-md px-8 py-6 text-2xl font-bold no-underline transition-all hover:shadow-xl sm:px-52',
              {
                'bg-theme-blue text-white': ctaButtonColor === 'blue',
                'bg-theme-purple text-white': ctaButtonColor === 'purple',
                'bg-theme-cyan text-theme-blue-darkest':
                  ctaButtonColor === 'cyan',
                'bg-theme-blue-dark text-white': ctaButtonColor === 'dark-blue',
                'text-theme-blue-darkest bg-white': ctaButtonColor === 'white',
              }
            )}
          >
            {ctaButtonText}
            <IoArrowForwardSharp className="ml-2 -rotate-45 text-3xl transition-transform group-hover:rotate-0" />
          </Link>
        </div>
      )}
    </div>
  );
}
