import classNames from 'classnames';
import type { ChangeEvent } from 'react';
import { IoInformationCircleOutline } from 'react-icons/io5';
import type {
  CalculatorAction,
  NumberInputGroupState,
  NumberInputItem,
  StateCategoryEnum,
} from '../blocks/calculators/types';
import { validateNumberInput } from '../blocks/calculators/validation';
import { CalculatorInput, Tooltip } from '@cardo/ui';

type CalculatorInputGroupProps = NumberInputItem & {
  className?: string;
  stateObj: NumberInputGroupState;
  stateKey: StateCategoryEnum;
  dispatch: React.Dispatch<CalculatorAction>;
  error?: { [key: string]: string | null };
  setError?: (name: string, message: string | null) => void;
  labelInputContainerClassName?: string;
};

export default function CalculatorInputGroup({
  label,
  name,
  icon,
  tooltip,
  validation,
  className,
  stateObj,
  stateKey,
  dispatch,
  error,
  setError,
  labelInputContainerClassName,
}: CalculatorInputGroupProps) {
  return (
    <div
      key={label}
      className={classNames('flex items-start gap-3', className)}
    >
      <img src={icon} alt="icon" className="" />
      <div
        className={classNames(
          'flex flex-grow flex-col gap-2 sm:justify-between',
          labelInputContainerClassName
        )}
      >
        <div className="flex gap-1">
          <div className="w-fit">{label}</div>
          {tooltip && (
            <Tooltip
              content={<span className="text-sm">{tooltip}</span>}
              childrenClassName="p-1"
            >
              <IoInformationCircleOutline />
            </Tooltip>
          )}
        </div>
        <div className="flex flex-col gap-1.5">
          <CalculatorInput
            currency={true}
            type="text"
            name={name}
            value={stateObj[name]}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const parsedValue = e.target.value
                ? parseInt(e.target.value.replace(/,/g, ''))
                : 0;
              if (validation && setError) {
                validateNumberInput({
                  validation,
                  value: parsedValue,
                  name,
                  setError: setError,
                });
              }
              dispatch({
                type: 'update',
                category: stateKey,
                name: e.target.name,
                value: isNaN(parsedValue) ? null : parsedValue,
              });
            }}
            onBlur={(e: ChangeEvent<HTMLInputElement>) => {
              if (validation && setError) {
                const parsedValue = e.target.value
                  ? parseInt(e.target.value.replace(/,/g, ''))
                  : 0;
                validateNumberInput({
                  validation,
                  value: parsedValue,
                  name,
                  setError: setError,
                });
              }
            }}
          />
          {error && (
            <>
              {!error[name] && <div className="h-5 w-full" />}
              {error[name] && (
                <div className="text-sm text-slate-500">{error[name]}</div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
