import type { CalculatorComponent, OmitComponentAttrs } from '@cardo/types';
import { CalculatorProvider } from '~/hooks/useCalculator';
import AmericanExpressGoldCalculator from './calculators/AmericanExpressGoldCalculator';
import { data as americanExpressGoldData } from './calculators/AmericanExpressGoldCalculator/data';
import AmericanExpressPlatinumCalculator from './calculators/AmericanExpressPlatinumCalculator';
import { data as americanExpressPlatinumData } from './calculators/AmericanExpressPlatinumCalculator/data';
import CapitalOneVentureXRewardsCalculator from './calculators/CapitalOneVentureXRewardsCalculator';
import { data as capitalOneVentureXRewardsData } from './calculators/CapitalOneVentureXRewardsCalculator/data';
import CitiCustomCashCalculator from './calculators/CitiCustomCashCalculator';
import { data as citiCustomCashData } from './calculators/CitiCustomCashCalculator/data';
import AmericanExpressBusinessPlatinumCalculator from './calculators/AmericanExpressBusinessPlatinumCalculator';
import { data as americanExpressBusinessPlatinumData } from './calculators/AmericanExpressBusinessPlatinumCalculator/data';
import ChaseInkBusinessPreferredCalculator from './calculators/ChaseInkBusinessPreferredCalculator';
import { data as chaseInkBusinessPreferredData } from './calculators/ChaseInkBusinessPreferredCalculator/data';
import ChaseSapphirePreferredCalculator from './calculators/ChaseSapphirePreferredCalculator';
import { data as chaseSapphirePreferredData } from './calculators/ChaseSapphirePreferredCalculator/data';

export default function CalculatorBase(
  props: OmitComponentAttrs<CalculatorComponent>
) {
  switch (props.calculator.data?.attributes.name?.toLowerCase()) {
    case 'citi custom cash card':
      return (
        <CalculatorProvider
          introOffer={props.calculator.data?.attributes.introOfferSection}
          data={citiCustomCashData}
        >
          <CitiCustomCashCalculator {...props} />
        </CalculatorProvider>
      );
    case 'american express gold card':
      return (
        <CalculatorProvider
          introOffer={props.calculator.data?.attributes.introOfferSection}
          data={americanExpressGoldData}
        >
          <AmericanExpressGoldCalculator {...props} />
        </CalculatorProvider>
      );
    case 'the platinum card from american express':
      return (
        <CalculatorProvider
          introOffer={props.calculator.data?.attributes.introOfferSection}
          data={americanExpressPlatinumData}
        >
          <AmericanExpressPlatinumCalculator {...props} />
        </CalculatorProvider>
      );
    case 'capital one venture x rewards credit card':
      return (
        <CalculatorProvider
          introOffer={props.calculator.data?.attributes.introOfferSection}
          data={capitalOneVentureXRewardsData}
        >
          <CapitalOneVentureXRewardsCalculator {...props} />
        </CalculatorProvider>
      );
    case 'the business platinum card from american express':
      return (
        <CalculatorProvider
          introOffer={props.calculator.data?.attributes.introOfferSection}
          data={americanExpressBusinessPlatinumData}
        >
          <AmericanExpressBusinessPlatinumCalculator {...props} />
        </CalculatorProvider>
      );
    case 'chase ink business preferred credit card':
      return (
        <CalculatorProvider
          introOffer={props.calculator.data?.attributes.introOfferSection}
          data={chaseInkBusinessPreferredData}
        >
          <ChaseInkBusinessPreferredCalculator {...props} />
        </CalculatorProvider>
      );
    case 'chase sapphire preferred credit card':
      return (
        <CalculatorProvider
          introOffer={props.calculator.data?.attributes.introOfferSection}
          data={chaseSapphirePreferredData}
        >
          <ChaseSapphirePreferredCalculator {...props} />
        </CalculatorProvider>
      );
    default:
      return null;
  }
}
