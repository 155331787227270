import type { ImageCarouselComponent, OmitComponentAttrs } from '@cardo/types';
import { generateImgProps, getAlt } from '~/lib/strapiImage';
import AwesomeSlider from 'react-awesome-slider';
import { IoArrowForwardSharp, IoArrowBackSharp } from 'react-icons/io5';

export default function ImageCarousel({
  images,
}: OmitComponentAttrs<ImageCarouselComponent>) {
  return (
    <div className="pb-4">
      <AwesomeSlider
        organicArrows={false}
        buttonContentRight={
          <IoArrowForwardSharp className="hover:text-6xl transition-all text-5xl" />
        }
        buttonContentLeft={
          <IoArrowBackSharp className="hover:text-6xl transition-all text-5xl" />
        }
      >
        {images.data.map((image) => (
          <div
            key={image.id}
            className="relative h-full flex justify-center items-center"
          >
            <img
              {...generateImgProps({ data: image })}
              alt={getAlt({ data: image })}
            />
            {image.attributes.caption !== image.attributes.name && (
              <div className="absolute left-0 bottom-0 bg-white py-0.5 sm:py-1 px-1.5 sm:px-3 text-xs opacity-80 rounded-tr-lg">
                {image.attributes.caption}
              </div>
            )}
          </div>
        ))}
      </AwesomeSlider>
    </div>
  );
}
