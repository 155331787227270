import * as React from 'react';

import classNames from 'classnames';
import { useRef, useState } from 'react';
import * as reactUse from 'react-use';
const { useClickAway, useHover } = reactUse;

type TooltipProps = {
  children: React.ReactNode;
  content: React.ReactNode;
  position?: 'top' | 'left';
  containerClassName?: string;
  childrenClassName?: string;
};

export function Tooltip({
  content,
  children,
  position = 'top',
  containerClassName,
  childrenClassName,
}: TooltipProps) {
  const [show, setShow] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useClickAway(ref, () => setShow(false));

  const element = () => (
    <div
      className={classNames(
        'cursor-help',
        childrenClassName,
        !childrenClassName?.includes('p-') && 'p-2'
      )}
    >
      {children}
    </div>
  );

  const [hoverable, hovered] = useHover(element);

  return (
    <div
      className={classNames(containerClassName, 'sm:relative')}
      ref={ref}
      onClick={() => setShow((prev) => !prev)}
    >
      {hoverable}
      {(hovered || show) && (
        <div
          className={classNames(
            'absolute mx-auto w-[70vw] left-1/2 -translate-x-1/2 sm:translate-x-0 sm:w-max sm:max-w-[300px] bg-gray-900 text-white rounded p-2 z-50',
            {
              'sm:bottom-full sm:left-full': position === 'top',
              'sm:bottom-1/2 sm:left-auto sm:right-full': position === 'left',
            }
          )}
        >
          {content}
        </div>
      )}
    </div>
  );
}
