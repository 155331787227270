import * as React from 'react';
import type { IntroOfferSectionComponent } from '@cardo/types';
import type {
  CalculatorAction,
  CalculatorData,
  CalculatorState,
} from '~/components/blocks/calculators/types';
import type { Validation } from '~/components/blocks/calculators/validation';

export const multipliersReccuringIntervalOptions = ['Yearly', 'Monthly'];

const CalculatorContext = React.createContext<
  | {
      state: CalculatorState;
      dispatch: React.Dispatch<CalculatorAction>;
      hasIntroOffer: boolean;
      earnedIntroOffer: boolean;
      earnedIntroOfferAmount: number;
      multipliersReccuringInterval: string;
      setMultipliersRecurringInterval: React.Dispatch<
        React.SetStateAction<number>
      >;
      creditsError: { [key: string]: string | null };
      setCreditsError: (name: string, message: string | null) => void;
      data: CalculatorData;
      perksError: { [key: string]: string | null };
      setPerksError: (name: string, message: string | null) => void;
      customCPPError: string | null;
      setCustomCPPError: React.Dispatch<React.SetStateAction<string | null>>;
      totalCreditsValue: number;
      cpp: number | null | undefined;
      bonusValue: number;
    }
  | undefined
>(undefined);

function reducer(state: CalculatorState, action: CalculatorAction) {
  switch (action.type) {
    case 'update':
      return {
        ...state,
        ...(action.category && {
          [action.category]: {
            ...state[action.category],
            [action.name]: action.value,
          },
        }),
      };
    case 'update-eligibility-question':
      return {
        ...state,
        introOffer: {
          ...state.introOffer,
          eligibilityQuestions: {
            ...state.introOffer.eligibilityQuestions,
            [action.name]: action.value,
          },
        },
      };
    default:
      throw new Error();
  }
}

type CalculatorProviderProps = {
  children: React.ReactNode;
  introOffer?: IntroOfferSectionComponent;
  data: CalculatorData;
};

const initialState: CalculatorState = {
  introOffer: {
    bonusOfferAmount: 0,
    bonusOfferAmountError: null,
    eligibilityQuestions: {},
  },
  spendCategories: {},
  credits: {},
  perks: {},
  redemption: {
    selectedOption: null,
    customValue: null,
  },
};

export function CalculatorProvider({
  children,
  introOffer,
  data,
}: CalculatorProviderProps) {
  const hasIntroOffer =
    !!introOffer &&
    !!introOffer?.eligibilityQuestions &&
    introOffer.eligibilityQuestions.length > 0;

  const [state, dispatch] = React.useReducer(
    reducer,
    initialState,
    (initialArg) => {
      if (!data) return initialArg;

      const { spendCategoryItems, creditsItems, perksItems } = data;

      return {
        ...initialArg,
        ...(hasIntroOffer && {
          introOffer: {
            ...initialArg.introOffer,
            bonusOfferAmount: introOffer.defaultBonusAmount || 0,
            eligibilityQuestions: introOffer.eligibilityQuestions?.reduce(
              (acc, question) => ({
                ...acc,
                [question.questionKey]: null,
              }),
              {}
            ),
          },
        }),
        spendCategories: spendCategoryItems.reduce(
          (acc, category) => ({
            ...acc,
            [category.name]: category.defaultValue,
          }),
          {}
        ),
        credits:
          creditsItems?.reduce(
            (acc, credit) => ({
              ...acc,
              [credit.name]: credit.defaultValue,
            }),
            {}
          ) ?? {},
        perks:
          perksItems?.reduce(
            (acc, perk) => ({
              ...acc,
              [perk.name]: perk.defaultValue,
            }),
            {}
          ) ?? {},
      };
    }
  );
  const [
    pointMultipliersReccuringIntervalIdx,
    setPointMultipliersReccuringIntervalIdx,
  ] = React.useState(0);
  const multipliersReccuringInterval = React.useMemo(
    () =>
      multipliersReccuringIntervalOptions[pointMultipliersReccuringIntervalIdx],
    [pointMultipliersReccuringIntervalIdx]
  );

  const earnedIntroOffer = React.useMemo(() => {
    if (!hasIntroOffer) return false;
    if (!state.introOffer.eligibilityQuestions) return false;
    return Object.values(state.introOffer.eligibilityQuestions).every(
      (value) => value === 'yes'
    );
  }, [hasIntroOffer, state.introOffer.eligibilityQuestions]);

  const earnedIntroOfferAmount = React.useMemo(() => {
    const { bonusOfferAmount, bonusOfferAmountError } = state.introOffer;
    if (bonusOfferAmountError) return 0;
    if (earnedIntroOffer) return bonusOfferAmount;
    return 0;
  }, [earnedIntroOffer, state.introOffer]);

  const [creditsError, setCreditsError] = React.useState<{
    [key: string]: string | null;
  }>({});
  function handleSetCreditsError(name: string, message: string | null) {
    setCreditsError((prev) => ({ ...prev, [name]: message }));
  }

  const [perksError, setPerksError] = React.useState<{
    [key: string]: string | null;
  }>({});
  function handleSetPerksError(name: string, message: string | null) {
    setPerksError((prev) => ({ ...prev, [name]: message }));
  }

  const [customCPPError, setCustomCPPError] = React.useState<string | null>(
    null
  );

  const totalCreditsValue = React.useMemo(
    () =>
      Object.entries(state.credits).reduce((acc, [key, value]) => {
        if (typeof value === 'number' && data.creditsItems) {
          const validation = data.creditsItems.find(
            (credit) => credit.name === key
          )?.validation;
          return (
            acc +
            (validation ? getValidationCappedValue(value, validation) : value)
          );
        }
        return acc;
      }, 0),
    [data.creditsItems, state.credits]
  );

  const cpp = React.useMemo(() => {
    if (!data?.redemptionItems) return null;

    const { selectedOption, customValue } = state.redemption;
    if (selectedOption === 'custom') {
      if (!customValue) return 0;
      let customValueAsNumber = Number(customValue);
      if (
        typeof customValueAsNumber !== 'number' ||
        customValueAsNumber < 0 ||
        isNaN(customValueAsNumber)
      )
        return 0;
      return customValue;
    }
    const selectedOptionObj = data.redemptionItems.find(
      (option) => option.name === selectedOption
    );
    return selectedOptionObj?.value;
  }, [state.redemption, data]);

  const bonusValue = React.useMemo(() => {
    if (customCPPError) {
      return 0;
    }

    if (cpp) {
      return (earnedIntroOfferAmount * cpp) / 100;
    }

    if (!data.redemptionItems) {
      return earnedIntroOfferAmount;
    }

    return 0;
  }, [customCPPError, cpp, data.redemptionItems, earnedIntroOfferAmount]);

  const value = {
    state,
    dispatch,
    hasIntroOffer,
    earnedIntroOffer,
    earnedIntroOfferAmount,
    multipliersReccuringInterval,
    setMultipliersRecurringInterval: setPointMultipliersReccuringIntervalIdx,
    creditsError,
    setCreditsError: handleSetCreditsError,
    perksError,
    setPerksError: handleSetPerksError,
    customCPPError,
    setCustomCPPError,
    totalCreditsValue,
    cpp,
    bonusValue,
    data,
  };
  return (
    <CalculatorContext.Provider value={value}>
      {children}
    </CalculatorContext.Provider>
  );
}

export function useCalculator() {
  const context = React.useContext(CalculatorContext);
  if (context === undefined) {
    throw new Error('useCalculator must be used within a CalculatorProvider');
  }
  return context;
}

const dataCategories: {
  [key: string]: 'spendCategoryItems' | 'creditsItems' | 'perksItems';
} = {
  spendCategories: 'spendCategoryItems',
  credits: 'creditsItems',
  perks: 'perksItems',
};

export function getValue(
  data: CalculatorData,
  state: CalculatorState,
  category: 'spendCategories' | 'credits' | 'perks',
  key: string
): number {
  const stateItems = state[category];
  if (!stateItems) return 0;

  const value = stateItems[key];
  if (!value) return 0;

  const dataCategory = dataCategories[category];

  const validation = getValidation(data, dataCategory, key);

  return getValidationCappedValue(value, validation);
}

export function getValidationCappedValue(
  value: number,
  validation: Validation | null | undefined
) {
  if (validation?.max && value > validation.max) {
    return validation.max;
  }
  if (validation?.min && value < validation.min) {
    return validation.min;
  }
  return value;
}

export function getValidation(
  data: CalculatorData,
  category: 'spendCategoryItems' | 'creditsItems' | 'perksItems',
  key: string
) {
  const stateItems = data[category];
  if (!stateItems || stateItems.length === 0) return null;

  const item = stateItems.find((item) => item.name === key);
  if (!item) return null;

  return item.validation;
}
