import type { CreditCardCtaComponent, OmitComponentAttrs } from '@cardo/types';
import CardmatchCta from './creditCardCtas/Cardmatch';

export default function CalculatorBase(
  cta: OmitComponentAttrs<CreditCardCtaComponent>
) {
  switch (cta.name?.toLowerCase()) {
    case 'cardmatch':
      return <CardmatchCta {...cta} />;
    default:
      return null;
  }
}
