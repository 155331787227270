import { Link, useLocation } from '@remix-run/react';
import { useState } from 'react';
import type {
  Blog,
  CreditCardComponent,
  CreditCardCtaComponent,
  OmitComponentAttrs,
} from '@cardo/types';
import { Block } from './BlockManager';
import classnames from 'classnames';
import { IoArrowForwardSharp, IoChevronDown } from 'react-icons/io5';
import { isCurrentUrl } from '~/lib/utils';
import DOMPurify from 'isomorphic-dompurify';

type CreditCardProps = {
  vertical?: boolean;
  initialDetailsOpen?: boolean;
  calculatorLink?: string;
  hideIfHideFromInfluencer?: boolean;
  showCtaBelow?: boolean;
};

export default function CreditCard({
  editorial,
  creditCard,
  calculatorLink,
  vertical = false,
  initialDetailsOpen = false,
  hideIfHideFromInfluencer = false,
  showCtaBelow = false,
}: OmitComponentAttrs<CreditCardComponent & CreditCardProps>) {
  const [detailsOpen, setDetailsOpen] = useState<boolean>(initialDetailsOpen);
  function toggleDetails() {
    setDetailsOpen((prev) => !prev);
  }

  const card = creditCard.data?.attributes;

  if (!card || (card.hideFromInfluencers && hideIfHideFromInfluencer))
    return null;

  const cardArtUrl = creditCard.data.attributes.cardArtUrl;
  const introOffer = creditCard.data.attributes.introOffer;
  const annualFee = creditCard.data.attributes.annualFee;

  return (
    <div className="flex flex-col">
      <div className="mx-auto flex w-full flex-col rounded-md border shadow-md">
        <div
          className={classnames(
            'flex flex-col justify-between gap-8 p-8',
            !vertical && 'lg:flex-row',
            vertical && '2xl:flex-row'
          )}
        >
          <div className="flex flex-col gap-8 lg:flex-row">
            <div
              className={classnames(
                'mx-auto flex w-11/12 flex-shrink-0 items-start justify-center rounded text-teal-400 sm:max-w-[250px]',
                {
                  'bg-indigo-800': !cardArtUrl,
                }
              )}
            >
              {!cardArtUrl && card.issuerName}
              {cardArtUrl && (
                <Link
                  to={`/go/${encodeURIComponent(card.slug ?? card.cardName)}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <figure className="flex w-full">
                    <img
                      src={cardArtUrl}
                      alt={`card art for the ${card.cardName}`}
                      className="z-10 mx-auto w-full rounded-lg object-cover"
                    />
                  </figure>
                </Link>
              )}
            </div>
            <div className="flex flex-col gap-6 lg:min-w-[220px]">
              <Link
                to={`/go/${encodeURIComponent(card.slug ?? card.cardName)}`}
                target="_blank"
                rel="noreferrer"
                className="no-underline hover:underline"
              >
                <h2 className="text-center text-2xl font-bold md:text-left">
                  {card.cardName}
                </h2>
              </Link>
              <div className="flex flex-col gap-2">
                {introOffer && (
                  <p className="text-center text-lg font-bold sm:text-left">
                    {introOffer}
                  </p>
                )}
                {card.belowIntroOffer && (
                  <div
                    className=""
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(card.belowIntroOffer),
                    }}
                  ></div>
                )}
                {card.cta && !showCtaBelow && (
                  <div className="flex flex-col pt-2">
                    <CTA cta={card.cta} />
                  </div>
                )}
              </div>
              {(card.editorial?.editorsTake || editorial?.editorsTake) && (
                <div className="flex flex-col gap-3">
                  {card.editorial?.editorsTake && !editorial?.editorsTake && (
                    <div
                      className="text-sm"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(card.editorial.editorsTake),
                      }}
                    ></div>
                  )}
                  {editorial?.editorsTake && (
                    <div
                      className="text-sm"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(editorial.editorsTake),
                      }}
                    ></div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-grow flex-col items-center justify-center gap-3">
            <Link
              to={`/go/${encodeURIComponent(card.slug ?? card.cardName)}`}
              target="_blank"
              rel="noreferrer"
              className="group flex flex-row items-center text-lg font-bold no-underline"
            >
              <span className="mr-2 whitespace-nowrap">View offer</span>
              <IoArrowForwardSharp className="-rotate-45 text-2xl transition-transform group-hover:rotate-0" />
            </Link>
            {((card.calculator.data && card.blog?.data) || calculatorLink) && (
              <CalculatorLink
                blog={card.blog?.data}
                calculatorLink={calculatorLink}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col-reverse border-t border-[#7F8C8D] sm:flex-row">
          <button
            type="button"
            onClick={toggleDetails}
            className={classnames(
              'flex flex-1 items-center justify-center gap-2 border-[#7F8C8D] py-5 text-lg font-bold ',
              detailsOpen && 'border-b sm:border-b-0'
            )}
          >
            Rewards Details{' '}
            <IoChevronDown
              className={classnames(
                'text-theme-blue duration-250 text-3xl transition-transform ease-in-out',
                detailsOpen && 'rotate-180'
              )}
            />
          </button>
          {card.blog?.data && (
            <Link
              to={`/articles/${card.blog.data.attributes.slug}`}
              className={classnames(
                'text-theme-blue-darkest group group flex flex-1 justify-center border-b border-[#7F8C8D] py-5 text-lg font-bold no-underline sm:border-l',
                !detailsOpen && 'sm:border-b-0',
                detailsOpen && 'sm:border-b'
              )}
            >
              <span className="mr-2 group-hover:underline">
                Read the full review
              </span>
              <IoArrowForwardSharp className="-rotate-45 text-2xl transition-transform group-hover:rotate-0" />
            </Link>
          )}
        </div>
        <div
          className={classnames('flex flex-col gap-6 p-8', {
            hidden: !detailsOpen,
            flex: detailsOpen,
          })}
        >
          {(card.editorial?.highlights || editorial?.highlights) && (
            <div className="editors-take flex flex-col gap-3">
              {card.editorial?.highlights && !editorial?.highlights && (
                <div
                  className="prose-sm max-w-none"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(card.editorial.highlights),
                  }}
                ></div>
              )}
              {editorial?.highlights && (
                <div
                  className="prose-sm max-w-none"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(editorial.highlights),
                  }}
                ></div>
              )}
            </div>
          )}
          <div className="flex flex-col gap-3">
            <p className="text-lg">
              <span className="text-lg font-bold">Annual Fee:</span>{' '}
              <span>{annualFee || 'None'}</span>
            </p>
          </div>
        </div>
      </div>
      {card.cta && showCtaBelow && (
        <div className="flex justify-center pt-6">
          <div className="flex flex-col rounded border border-green-100 bg-green-50 px-8 py-4 shadow-lg">
            <CTA cta={card.cta} />
          </div>
        </div>
      )}
    </div>
  );
}

export function CTA({ cta }: { cta: CreditCardCtaComponent | null }) {
  if (!cta) return null;

  return (
    <Block
      block={{
        ...cta,
        __component: 'client.credit-card-cta',
      }}
    />
  );
}

export function CalculatorLink({
  blog,
  calculatorLink,
}: {
  blog: Blog | null | undefined;
  calculatorLink?: string;
}) {
  const location = useLocation();

  let link = `/articles/${blog?.attributes.slug}`;

  if (calculatorLink) {
    link = calculatorLink;
  }

  if (!link) return null;

  if (isCurrentUrl(link, location)) return null;

  return (
    <Link
      to={link}
      className="text-theme-blue-darkest group flex flex-row items-center text-lg font-bold no-underline"
    >
      <span className="mr-2 whitespace-nowrap">Calculator</span>
      <IoArrowForwardSharp className="-rotate-45 text-2xl transition-transform group-hover:rotate-0" />
    </Link>
  );
}
