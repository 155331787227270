type CalculatorStructureProps = {
  heading: string;
  cardName: string;
  children: React.ReactNode;
  showCalculationMethodology?: boolean;
};

export default function CalculatorStructure({
  heading,
  cardName,
  children,
  showCalculationMethodology = true,
}: CalculatorStructureProps) {
  return (
    <div className="flex flex-col">
      {heading && (
        <div className="prose max-w-none text-theme-blue-darkest mb-10 leading-5">
          <h1>{heading}</h1>
          <p>
            Cardonomics calculates the expected value of the{' '}
            {cardName || 'card'} based on the information you provide. The
            expected value is calculated based on the dollar value you input
            into the calculator.
          </p>
          <p>
            The default figures in the calculator are just an example, so you
            should enter your own information for more accurate results. The
            methodology for this calculator is explained at the bottom of the
            post.
          </p>
        </div>
      )}
      <div className="flex flex-col shadow-md">{children}</div>
      {showCalculationMethodology && (
        <div className="prose max-w-none text-theme-blue-darkest mt-10 leading-5">
          <h1>Calculation Methodology</h1>
          <p>
            The calculator's results are determined by several factors,
            including your ability to meet the minimum spending requirement to
            earn the welcome offer, spending habits in different categories,
            point redemption strategy, and how much relative value you place on
            other benefits.
          </p>
          <p>
            The "Years" in the calculator refer to the cardmember year, which
            begins when you are approved for the card. The first year of
            membership is the first 12 months after approval, and the second
            year is the 12 months following that.
          </p>
        </div>
      )}
    </div>
  );
}
