import classNames from 'classnames';
import type { BlogPostsComponent, OmitComponentAttrs } from '@cardo/types';
import BlogCard from '../BlogCard';

export default function BlogPosts({
  posts,
  heading,
  subheading,
}: OmitComponentAttrs<BlogPostsComponent>) {
  return (
    <div className="flex flex-col items-center">
      {heading && (
        <h2
          className={classNames('text-center', {
            'mb-6 sm:mb-14': !subheading,
            'mb-3': !!subheading,
          })}
        >
          {heading}
        </h2>
      )}
      {subheading && <h5 className="mb-6 sm:mb-14">{subheading}</h5>}
      {posts.length > 0 && (
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-8 w-full">
          {posts.map((post) =>
            post.blog?.data?.id ? (
              <BlogCard
                key={post.blog.data.id}
                {...post.blog.data.attributes}
                className="flex-grow"
              />
            ) : null
          )}
        </div>
      )}
    </div>
  );
}
