import classNames from 'classnames';
import { MoneyBill } from '@cardo/ui';
import { formatInt } from '~/lib/utils';

type ExpectedValueProps = {
  earnedIntroOffer: boolean;
  totalValuePerYear: number;
  bonusValue: number;
  calculationExplanationText: string;
  containerClassName?: string;
  bgColorClassName?: string;
  headingColorClassName?: string;
  year1Value?: number;
  year2Value?: number;
  heading: string;
};

export default function ExpectedValue({
  earnedIntroOffer,
  totalValuePerYear,
  bonusValue,
  calculationExplanationText,
  containerClassName,
  bgColorClassName,
  headingColorClassName,
  year1Value,
  year2Value,
  heading,
}: ExpectedValueProps) {
  return (
    <div
      className={classNames(
        'flex flex-col px-6 py-20 gap-6',
        containerClassName,
        bgColorClassName
      )}
    >
      <div className="flex flex-col gap-2 items-center">
        <div className="flex flex-row gap-2 items-center">
          <MoneyBill width={30} height={30} />
          <h4
            className={classNames(
              'text-center sm:text-left',
              headingColorClassName
            )}
          >
            {heading}
          </h4>
        </div>
        <p className="text-lg font-bold text-center">
          {calculationExplanationText}
        </p>
      </div>
      <div className="bg-slate-100 rounded h-px w-11/12 mx-auto" />
      <div className="flex justify-center gap-6 flex-wrap">
        {earnedIntroOffer && (
          <>
            <div className="flex gap-2 flex-wrap">
              <div className="font-bold text-lg whitespace-nowrap">Year 1:</div>
              <div className="text-4xl font-bold">
                $
                {formatInt(
                  Math.round(year1Value || totalValuePerYear + bonusValue)
                )}
              </div>
            </div>
            <div className="flex gap-2 flex-wrap">
              <div className="font-bold text-lg whitespace-nowrap">Year 2:</div>
              <div className="text-4xl font-bold">
                ${formatInt(Math.round(year2Value || totalValuePerYear))}
              </div>
            </div>
          </>
        )}
        {!earnedIntroOffer && (
          <div className="text-4xl font-bold">
            ${formatInt(Math.round(year2Value || totalValuePerYear))}
          </div>
        )}
      </div>
    </div>
  );
}
