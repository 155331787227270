import { Link } from '@remix-run/react';
import type { CreditCardCategory } from '@cardo/types';
import { generateImgProps } from '~/lib/strapiImage';

export default function CreditCardCategoryCard({
  category,
}: {
  category: CreditCardCategory;
}) {
  if (!category) return null;
  return (
    <Link
      to={`/cards/${category.attributes.slug}`}
      className="flex flex-col gap-4 h-full justify-center sm:justify-start items-center rounded-lg px-4 py-4 sm:p-5 border shadow-md no-underline text-theme-blue-darkest group hover:shadow-lg transition-all bg-white"
      prefetch="intent"
    >
      <div className="flex h-6 sm:h-11 justify-center items-center">
        {category.attributes.thumbnail.data && (
          <figure className="group-hover:scale-110 transition-transform">
            <img
              {...generateImgProps(category.attributes?.thumbnail)}
              alt={`icon for ${category.attributes.name} category`}
              className="h-full mx-auto"
            />
          </figure>
        )}
      </div>
      <h2 className="text-center text-base sm:text-2xl leading-tight">
        {category.attributes.name}
      </h2>
    </Link>
  );
}
