import plane from '~/images/plane-departure-solid.svg';
import creditCard from '~/images/credit-card-solid.svg';
import hotel from '~/images/hotel-solid.svg';
import dumbbell from '~/images/dumbbell-solid.svg';
import car from '~/images/car-solid.svg';
import passport from '~/images/passport-solid.svg';
import shoppingBag from '~/images/shopping-bag-solid.svg';
import shoppingBasket from '~/images/shopping-basket-solid.svg';

import type { NumberInputItem, Redemption } from '../types';

export const spendCategoryItems: NumberInputItem[] = [
  {
    label:
      'Flights booked directly with airlines or on amextravel.com (5X points)',
    name: 'flights',
    icon: plane,
    defaultValue: 4000,
  },
  {
    label: 'Prepaid hotels booked on amextravel.com (5X points)',
    name: 'hotels',
    icon: hotel,
    defaultValue: 2000,
  },
  {
    label: 'All other purchases',
    name: 'other',
    icon: creditCard,
    defaultValue: 2000,
  },
];

export const creditsItems: NumberInputItem[] = [
  {
    label: '$200 Uber Cash',
    name: 'uberCredit',
    icon: car,
    defaultValue: 200,
    tooltip:
      'Get up to $15 per month and $35 for December for rides or Uber Eats orders in the U.S. Enrollment is required, and you must add your Platinum Card® to your Uber account, and then pay with any AMEX card.',
    validation: {
      min: 0,
      max: 200,
    },
  },
  {
    label: '$200 Airline Fee Credit',
    name: 'airlineFeeCredit',
    icon: plane,
    defaultValue: 200,
    tooltip:
      'Receive up to $200 per calendar year in statement credit when you charge airline incidental fees from one qualifying airline to your card. Enrollment required.',
    validation: {
      min: 0,
      max: 200,
    },
  },
  {
    label: '$200 Hotel Credit',
    name: 'hotelCredit',
    icon: hotel,
    defaultValue: 200,
    tooltip:
      'Prepaid bookings through the Fine Hotels + Resorts® program or The Hotel Collection via American Express Travel when you pay with your Platinum Card. The Hotel Collection requires a minimum two-night stay.',
    validation: {
      min: 0,
      max: 200,
    },
  },
  {
    label: '$100 Saks Credit',
    name: 'saksCredit',
    icon: shoppingBag,
    defaultValue: 100,
    tooltip:
      'Up to $50 in statement credits semi-annually. Enrollment required.',
    validation: {
      min: 0,
      max: 100,
    },
  },
  {
    label: '$199 CLEAR® Plus Credit',
    name: 'clearCredit',
    icon: passport,
    defaultValue: 199,
    tooltip:
      'Up to $199 in statement credit per year when you pay with your Platinum Card. Enrollment required.',
    validation: {
      min: 0,
      max: 199,
    },
  },
  {
    label: '$155 Walmart+ Credit',
    name: 'walmartCredit',
    icon: shoppingBasket,
    defaultValue: 0,
    tooltip:
      'Receive a statement credit for $12.95+ tax/month to cover a Walmart+ membership. Enrollment required.',
    validation: {
      min: 0,
      max: 155,
    },
  },
  {
    label: '$300 Equinox Credit',
    name: 'equinoxCredit',
    icon: dumbbell,
    defaultValue: 0,
    tooltip:
      'Up to $300 back in statement credits each year on Equinox+ or any Equinox club memberships. Enrollment required.',
    validation: {
      min: 0,
      max: 300,
    },
  },
  {
    label: '$300 SoulCycle Credit',
    name: 'soulCycleCredit',
    icon: shoppingBasket,
    defaultValue: 0,
    tooltip:
      'Get a $300 statement credit after each time you use your Platinum Card to purchase one SoulCycle at-home bike. Terms apply. Enrollment required.',
    validation: {
      min: 0,
    },
  },
  {
    label: '$240 Digital Entertainment Credit',
    name: 'digitalEntertainmentCredit',
    icon: shoppingBag,
    defaultValue: 240,
    tooltip:
      'Up to $20 in statement credits each month on eligible purchases at participating partners when you use the Platinum Card. Enrollment required.',
    validation: {
      min: 0,
      max: 240,
    },
  },
  {
    label: '$120 for Global Entry or $85 for TSA Pre✓® credit',
    name: 'preCheckCredit',
    icon: passport,
    defaultValue: 0,
    tooltip:
      'Statement credit either once every four years for Global Entry or once every 4.5 years for TSA Precheck. Terms apply. Enrollment required.',
    validation: {
      min: 0,
      max: 120,
    },
  },
];

export const perksItems: NumberInputItem[] = [
  {
    label: 'Centurion Lounges',
    name: 'centurionLoungePerk',
    icon: plane,
    defaultValue: 100,
  },
  {
    label: 'Delta Sky Clubs',
    name: 'deltaSkyClubPerk',
    icon: plane,
    defaultValue: 50,
    tooltip: "Only accessible when you're flying Delta.",
  },
  {
    label: 'Priority Pass and other partner airport lounges',
    name: 'priorityPassPerk',
    icon: plane,
    defaultValue: 50,
  },
  {
    label: 'Hotel status (Marriott Gold + Hilton Gold)',
    name: 'hotelStatusPerk',
    icon: hotel,
    defaultValue: 0,
    tooltip: 'Enrollment required.',
  },
  {
    label: 'Access to Fine Hotels and Resorts Program',
    name: 'fineHotelsPerk',
    icon: hotel,
    defaultValue: 0,
    tooltip:
      'Get benefits like $100 dining or spa credit, 1-level room upgrades, or late checkout when you book through the Fine Hotels and Resorts program via Amex Travel. Benefits vary by property. Terms apply.',
  },
  {
    label: 'Other benefits (Car rental status, Resy, travel protections, etc.)',
    name: 'otherPerks',
    icon: creditCard,
    defaultValue: 0,
  },
];

export const redemptionItems: Redemption[] = [
  {
    label: 'Statement credit',
    name: 'statementCredit',
    value: 0.6,
  },
  {
    label: 'American Express® Business Checking',
    name: 'businessChecking',
    value: 0.8,
  },

  {
    label: 'Booking travel',
    name: 'bookingTravel',
    value: 1,
  },
  {
    label: 'Amex Business Checking w/ Business Platinum',
    name: 'businessCheckingWithBusinessPlatinum',
    value: 1,
  },
  {
    label: 'Cashing out via Schwab Platinum',
    name: 'cashingOutViaSchwabPlatinum',
    value: 1.1,
  },
  {
    label: 'Booking travel w/ Business Platinum rebate',
    name: 'bookingTravelWithBusinessPlatinumRebate',
    value: 1.25,
  },
  {
    label: 'Transferring to airline partners (target value)',
    name: 'transferringToAirlinePartners',
    value: 2,
  },
  {
    label: 'Custom',
    name: 'custom',
    value: null,
    acceptInput: true,
  },
];

export const data = {
  spendCategoryItems,
  creditsItems,
  perksItems,
  redemptionItems,
  annualFee: 695,
};
