import type { CreditCardCtaComponent, OmitComponentAttrs } from '@cardo/types';

export default function CardmatchCta(
  cta: OmitComponentAttrs<CreditCardCtaComponent>
) {
  let href;

  if (cta.link && !cta.path) {
    href = encodeURI(cta.link);
  }

  if (cta.path && !cta.link) {
    if (cta.path.startsWith('/')) {
      href = encodeURI(cta.path);
    } else {
      href = encodeURI(`/${cta.path}`);
    }
  }

  return (
    <div className="text-green-700">
      <span className="pr-1">✅</span> Check if you're{' '}
      <strong>pre-approved</strong> for this card or{' '}
      <strong>special offers</strong> using the{' '}
      <a
        href={href}
        className="font-bold text-blue-500 underline hover:text-blue-700"
        target="_blank"
        rel="noreferrer"
      >
        CardMatch Tool.
      </a>
    </div>
  );
}
