import { formatInt } from '~/lib/utils';

export type Validation = {
  min?: number;
  max?: number;
};

export function validateNumberInput({
  validation,
  name = '',
  value,
  setError,
}: {
  validation: Validation;
  name?: string;
  value: number;
  setError: (name: string, message: string | null) => void;
}) {
  try {
    if (isNaN(value)) {
      setError(name, null);
      return;
    }

    if (
      validation.min &&
      validation.max &&
      (value < validation.min || value > validation.max)
    ) {
      setError(
        name,
        `Must be between ${formatInt(validation.min)} and ${formatInt(
          validation.max
        )}`
      );
      return;
    }

    if (validation.min && value < validation.min) {
      setError(name, `Must be at least ${formatInt(validation.min)}`);
      return;
    }

    if (validation.max && value > validation.max) {
      setError(name, `Must be at most ${formatInt(validation.max)}`);
      return;
    }

    setError(name, null);
  } catch (_err) {
    setError(name, null);
  }
}
