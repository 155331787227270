import classNames from 'classnames';
import type { ChangeEvent } from 'react';
import { CalculatorInput } from '@cardo/ui';
import type { Redemption, RedemptionState, CalculatorAction } from '../types';
import { validateNumberInput } from '../validation';

type RedemptionRadiosProps = {
  redemptionItems: Redemption[];
  redemption: RedemptionState;
  dispatch: React.Dispatch<CalculatorAction>;
  setCustomCPPError: (message: string | null) => void;
  customCPPError: string | null;
  header?: React.ReactNode;
  units?: string;
};

export default function RedemptionRadios({
  redemptionItems,
  redemption,
  dispatch,
  setCustomCPPError,
  customCPPError,
  header,
  units,
}: RedemptionRadiosProps) {
  return (
    <>
      {header && <div>{header}</div>}
      {redemptionItems.map((option, idx) => (
        <div key={idx} className="flex gap-3 items-center">
          <label className="flex gap-3 items-start cursor-pointer">
            <div
              className={classNames(
                'flex items-center gap-3',
                option.name === 'custom' && 'mt-1'
              )}
            >
              <input
                type="radio"
                name="selectedOption"
                value={option.name}
                checked={redemption.selectedOption === option.name}
                onChange={(e) => {
                  dispatch({
                    type: 'update',
                    category: 'redemption',
                    name: e.target.name,
                    value: e.target.value,
                  });
                }}
              />
              <span>{option.label}</span>
            </div>
            {option.name === 'custom' && (
              <div className="flex flex-col gap-1.5">
                <div className="flex items-center gap-2">
                  <CalculatorInput
                    type="text"
                    name="customValue"
                    value={redemption.customValue}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const parsedValue = e.target.value
                        ? parseFloat(e.target.value)
                        : 0;
                      if (e.target.value) {
                        validateNumberInput({
                          value: parsedValue,
                          name: '',
                          setError: (_, message) => setCustomCPPError(message),
                          validation: {
                            min: 0.6,
                            max: 30,
                          },
                        });
                      }
                      dispatch({
                        type: 'update',
                        category: 'redemption',
                        name: e.target.name,
                        value: e.target.value,
                      });
                    }}
                    onFocus={() => {
                      dispatch({
                        type: 'update',
                        category: 'redemption',
                        name: 'selectedOption',
                        value: 'custom',
                      });
                    }}
                    className="w-16"
                  />
                  <span>{units ?? 'CPP'}</span>
                </div>
                {!customCPPError && <div className="h-5 w-full" />}
                {customCPPError && (
                  <div className="text-slate-500 text-sm">{customCPPError}</div>
                )}
              </div>
            )}
          </label>
        </div>
      ))}
    </>
  );
}
