import * as React from 'react';

import classNames from 'classnames';

export function Capitalize({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <span className={classNames('capitalize', className)}>{children}</span>
  );
}
