import DOMPurify from 'isomorphic-dompurify';

interface HTMLEmbedProps {
  html: string;
}

export default function HTMLEmbed({ html }: HTMLEmbedProps) {
  const sanitizedHtml = DOMPurify.sanitize(html, { ADD_TAGS: ['iframe'] });

  return (
    <div
      className="prose text-theme-blue-darkest max-w-none prose-figure:mx-auto prose-img:shadow-lg"
      dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
    ></div>
  );
}
