import plane from '~/images/plane-departure-solid.svg';
import box from '~/images/box-solid.svg';
import laptop from '~/images/laptop-solid.svg';
import wifi from '~/images/wifi-solid.svg';
import creditCard from '~/images/credit-card-solid.svg';

import type { NumberInputItem, Redemption } from '../types';

export const spendCategoryItems: NumberInputItem[] = [
  {
    label: 'Travel',
    name: 'travel',
    icon: plane,
    defaultValue: 3_000,
    validation: {
      min: 0,
    },
  },
  {
    label: 'Shipping purchases',
    name: 'shippingPurchases',
    icon: box,
    defaultValue: 5_000,
    validation: {
      min: 0,
    },
  },
  {
    label:
      'Advertising purchases made with social media sites and search engines',
    name: 'advertisingPurchases',
    icon: laptop,
    defaultValue: 10_000,
    validation: {
      min: 0,
    },
  },
  {
    label: 'Internet, cable and phone services',
    name: 'internetCablePhoneServices',
    icon: wifi,
    defaultValue: 1_000,
    validation: {
      min: 0,
    },
  },
  {
    label: 'All other purchases',
    name: 'allOtherPurchases',
    icon: creditCard,
    defaultValue: 1_000,
    validation: {
      min: 0,
    },
  },
];

export const redemptionItems: Redemption[] = [
  {
    label: 'Statement credit = 1 CPP',
    name: 'statementCredit',
    value: 1,
  },
  {
    label: 'Gift cards = 1 CPP',
    name: 'giftCards',
    value: 1,
  },
  {
    label: 'Travel via Chase Travel Portal = 1.25 CPP',
    name: 'travelViaChaseTravelPortal',
    value: 1.25,
  },
  {
    label: 'Transferring to airline partners (target value) = 2 CPP',
    name: 'transferringToAirlinePartners',
    value: 2,
  },
  {
    label: 'Custom',
    name: 'custom',
    value: null,
    acceptInput: true,
  },
];

export const data = {
  spendCategoryItems,
  redemptionItems,
  annualFee: 95,
};
